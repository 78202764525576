/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Grid, Heading, Link } from 'theme-ui';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { AiOutlinePlaySquare } from 'react-icons/ai';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';

const PhotosPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const instagramMedia = props.data.instagram.nodes;

  return (
    <>
      <Helmet
        title={t({ id: `Photos.Title`, message: `Photos & Videos` })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Photos.MetaDescription`,
            message: `See photos and videos of girls wearing KissCurls hair extensions`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Photos.BreadcrumbsTitle`,
              message: `Photos & Videos`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="Photos.Heading">Photos & Videos</Trans>
        </Heading>

        <Grid columns={[1, 2, 3]} mt={[2, 3]}>
          {instagramMedia.map((i, k) => (
            <Box
              as={i.mediaType === 'VIDEO' && i.original !== null ? Link : Box}
              href={i.original}
              target="_blank"
              rel="nofollow noopener noreferrer"
              key={k}
              sx={{ position: 'relative' }}
            >
              {i.mediaType === 'VIDEO' && i.original !== null ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    opacity: 1,
                    zIndex: 1,
                  }}
                >
                  <AiOutlinePlaySquare size="100" sx={{ color: 'secondary' }} />
                </Box>
              ) : (
                ''
              )}
              <GatsbyImage image={getImage(i.localFile)} alt={i.caption} />
            </Box>
          ))}
          <Box></Box>
        </Grid>
      </Box>
    </>
  );
};

export const PhotosPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
    instagram: allInstaNode(
      filter: { caption: { regex: "/#kisscurlsgirls/i" } }
      sort: { order: DESC, fields: timestamp }
    ) {
      nodes {
        username
        permalink
        mediaType
        preview
        caption
        original
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default PhotosPage;
